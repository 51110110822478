import styled from 'styled-components';
import { getLayout, getMediaQuery, getSpacerAsPixels, getVerticalSpaceAsPixels, GRID, Layout, Size, VerticalSpaceVariant } from '../../../../theme/layout';
import { getTypography, Type } from '../../../../theme/typography';
import { ShareButtons } from '../../../core/shareButtons';

export const MainContainer = styled.div`
  ${getLayout(Layout.BLOCK)}
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`;

export const Description = styled.div`
  ${getTypography(Type.BODY_1)}
  grid-column: 1 / span 8;
  margin-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
    margin-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 4 / span 6;
    margin-bottom: 0;
  }
`

export const Introduction = styled.div`
  ${GRID}
  margin-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    margin-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.MAX)}{
    margin-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
  }
`;

export const Sidebar = styled.div`
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }
  
  ${getMediaQuery(Size.LARGE)}{
    grid-column: 1 / span 2;
    grid-row: 1;
  }
`;

export const Share = styled(ShareButtons)`
  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getSpacerAsPixels({multiplier:1})};
    border-top: 1px solid ${({ theme }) => theme.primary.hex};
  }
`;

export const ShowsContainer = styled.div`
  ${getLayout(Layout.BLOCK)}
  background-color: ${({ theme }) => theme.background.hex};
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`