import React from "react";
import { ThemeProvider } from "styled-components";
import { useBrandTheme } from "../../../../context/brand";
import { useDictionary } from "../../../../context/dictionary";
import { Layout } from "../../../../theme/layout";
import { BlockContainer } from "../../../block/container";
import { ShowSlider } from "../../../show/showSlider";
import { VideoList } from "../../../video/videoList";
import { Description, Introduction, MainContainer, Share, ShowsContainer, Sidebar } from "./show.styles";
import { BodyShowProps } from "./show.types";

export const BodyShow = ({videos,shows,title,description,pageURL}:BodyShowProps) => {
  const lightTheme = useBrandTheme("light");
  const labelTvVideos = useDictionary("labelTvVideos");
  const labelTvVideosCount = useDictionary("labelTvVideosCount");
  const labelTvMoreShows = useDictionary("labelTvMoreShows");
  
  
  return (
    <>
     <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={true} extraSpaceAfter={true}>
      <Introduction>
        <Description>{description}</Description>
        <Sidebar>
          <Share url={pageURL} title={title} />
        </Sidebar>
      </Introduction>
      <VideoList items={videos} heading={labelTvVideos} subheading={`${videos.length} ${labelTvVideosCount}`} />
    </BlockContainer>
    <ThemeProvider theme={lightTheme}>
      <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={true} extraSpaceAfter={true}>
        <ShowSlider items={shows} heading={labelTvMoreShows} />
      </BlockContainer>
    </ThemeProvider>
  </>
  )
};