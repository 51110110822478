import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { isEmpty, map, path, prop } from 'ramda';

import { getSchema } from '../common/schema';

import { SEO } from '../components/core/seo';
import { getInternalURL } from '../common/link';
import { CoverMedia } from '../components/cover/media/media.component';
import { BodyShow } from '../components/layout/body/show';
import { ShowSlider } from '../components/show/showSlider';
import { CoverVariant } from '../components/cover/media/media.types';

export const query = graphql`
  query TvShowQuery($id: String!, $locale: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    home: datoCmsTvHome(locale: { eq: $locale }) {
      model {
        ...PageModel
      }
      title
    }
    page: datoCmsTvShow(id: { eq: $id }) {
      model {
        ...PageModel
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      title
      description
      coverMedia{
        ...MediaCover
      }
      coverMediaPortrait{
        ...MediaCoverPortrait
      }
      coverVariant
      videos{
        ...TvVideo
      }
    }
    shows: allDatoCmsTvShow(
      sort: {fields: meta___updatedAt, order: DESC},
      filter: {slug: {ne: null}, locale: {eq: $locale}}
    ){
      nodes{
        ...Show
      }
    }
  }
`;

const TvShow = ({ data, pageContext }: any) => {
  const settings:any = path(['settings', 'siteMetadata'], data);
  const page = prop('page', data);
  const home = prop('home', data);
  const site = prop('site', data);
  const shows = path(['shows','nodes'], data);

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });
  const pageURL = settings.siteUrl + getInternalURL(page);

  return (
    <Fragment>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        locale={pageContext.locale}
        defaultLocale={pageContext.defaultLocale}
        alternateLocales={pageContext.alternateLocales}
      />
      <CoverMedia heading={page.title} breadcrumb={home} defaultMedia={page.coverMedia} portraitMedia={page.coverMediaPortrait} variant={page.coverVariant} />
      <BodyShow videos={page.videos} shows={shows} description={page.description} title={page.title} pageURL={pageURL} />
    </Fragment>
  );
};

export default TvShow;