import styled from "styled-components";

export const Container = styled.div`
  
`;

export const Content = styled.div`
  position: relative;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
`;

export const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
`