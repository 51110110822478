import React from "react";
import { Container, Content, Image } from "./videoThumbnail.styles";
import { VideoThumbnailProps } from "./videoThumbnail.types";

export const VideoThumbnail = ({thumbnailUrl,width,height,className}:VideoThumbnailProps) => {
  const aspectRatio = width / height;

  return (
    <Container className={className}>
      <Content>
        <Image src={thumbnailUrl} aspectRatio={aspectRatio} />
      </Content>
    </Container>
  )
}