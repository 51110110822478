import React, { useState } from "react";
import { useIsRTL } from "../../../context/locale";
import { Card } from "../../core/card";
import { VideoThumbnail } from "../../core/videoThumbnail";
import { Badge, Duration, Heading, HeadingInner, Thumbnail, ThumbnailContainer } from "./videoCard.styles";
import { VideoCardProps } from "./videoCard.types";
import PlayIcon from '../../../images/play.svg';
import { secondsToFormattedString } from "../../../common/date";

export const VideoCard = ({video,mobileLayout,desktopLayout}:VideoCardProps) => {
  const isRTL = useIsRTL();
  
  return (
    <Card mobileLayout={mobileLayout} desktopLayout={desktopLayout}
      header={
        <Heading page={video}>
          <HeadingInner>
            {video.title}
          </HeadingInner>
        </Heading>
      }
    >
      <ThumbnailContainer page={video}>
        <Thumbnail {...video.media} />
        <Badge rtl={isRTL}><PlayIcon />{video.duration &&<Duration>{secondsToFormattedString(video.duration)}</Duration>}</Badge>
      </ThumbnailContainer>
    </Card>
  )
}